jQuery(document).ready(function() {

equalheight = function(container) {
    if (jQuery(window).width() > 767) {
        var currentTallest = 0,
            currentRowStart = 0,
            rowDivs = [],
            jQueryel;

        jQuery(container).each(function() {
            jQueryel = jQuery(this);
            jQuery(jQueryel).innerHeight('auto');
            rowDivs.push(jQueryel);
            currentTallest = (currentTallest < jQueryel.innerHeight()) ? (jQueryel.innerHeight()) : (currentTallest);

            for (currentDiv = 0; currentDiv < rowDivs.length; currentDiv++) {
                rowDivs[currentDiv].innerHeight(currentTallest);
            }
        });
    } else {
        jQuery(container).height('auto');
    }
};

equalheightformobile = function(container) {
    if (jQuery(window).width() > 319) {
        var currentTallest = 0,
            currentRowStart = 0,
            rowDivs = [],
            jQueryel;

        jQuery(container).each(function() {
            jQueryel = jQuery(this);
            jQuery(jQueryel).innerHeight('auto');
            rowDivs.push(jQueryel);
            currentTallest = (currentTallest < jQueryel.innerHeight()) ? (jQueryel.innerHeight()) : (currentTallest);

            for (currentDiv = 0; currentDiv < rowDivs.length; currentDiv++) {
                rowDivs[currentDiv].innerHeight(currentTallest);
            }
        });
    } else {
        jQuery(container).height('auto');
    }
};


   //new Menu Responsive
     jQuery(".mobile-icon a").on("click touchstart", function (event) {
        event.preventDefault();
        event.stopPropagation();
        jQuery("body").toggleClass("open-menu");

    });

    jQuery(document).on('click touchstart', function () {
        jQuery('body').removeClass('open-menu');
    });

     jQuery(".site-header .header-right").on("click touchstart", function (event) {
        event.stopPropagation();
    });

    jQuery(".touch .navigation ul li > a").on("click touchstart",function(event){

       if(!jQuery(this).parent().hasClass("open") && jQuery(this).parent().has("ul").length > 0)
       {
        event.preventDefault();
        event.stopPropagation();
        jQuery(this).parent().addClass("open").siblings().removeClass("open");
       }
      });

   /*search*/
       jQuery(".search-section .search-bar").click(function (event) {
          event.stopPropagation();
          jQuery(this).next(".search-form").slideToggle();
        });

       jQuery(document).on("click", function (e) {
         var popup = jQuery(".search-section");
            if (!jQuery('.search-form').is(e.target) && !popup.is(e.target) && popup.has(e.target).length == 0) {
               jQuery(".search-form").slideUp();
            }
        });

    /**/
     jQuery(".banner-slider").slick({
        //dots: (jQuery(this).find('.banner-slider .item').length > 1) ? true : false,
        autoplay: true,
        autoplaySpeed: 4000,
        infinite: true,
        nextArrow: '<div role="button" class="slick-next" title="Next slide"><img src="/wp-content/themes/vhpd/assets/media/arrow.svg" alt=""></div>',
        prevArrow: '<div role="button" class="slick-prev" title="Previous slide"><img src="/wp-content/themes/vhpd/assets/media/arrow.svg" alt=""></div>',
        slidesToScroll: 1,
        slidesToShow: 1,
      });

 globle();
});

jQuery(window).load(function() {

 globle();
});

jQuery(window).resize(function() {

 globle();
});


function globle() {
    equalheightformobile('.iapd-div');
    equalheight('.iapd-div > div');
    equalheight('.site-footer .row > div');
}

jQuery(document).ready(function() {
  function close_accordion_section() {
   jQuery('.accordion .accordion-section-title').removeClass('active');
    jQuery('.accordion .accordion-section-content').slideUp(300).removeClass('open');
  }

  jQuery('.accordion-section-title').click(function(e) {
    // Grab current anchor value
    var currentAttrValue = jQuery(this).attr('href');

    if(jQuery(e.target).is('.active')) {
      close_accordion_section();
    }else {
      close_accordion_section();

      // Add active class to section title
     jQuery(this).addClass('active');
      // Open up the hidden content panel
      jQuery('.accordion ' + currentAttrValue).slideDown(300).addClass('open');
    }

    e.preventDefault();
  });
});
